import { useResetRecoilState } from "recoil";
import { newHarkState } from "../atoms/NewHarkStateAtom";
import { recordingCompleteState } from "../atoms/RecordingStateAtom";

export const useResetRecordingAtoms = () => {
  const resetRecordingCompleteState = useResetRecoilState(
    recordingCompleteState
  );
  const resetNewHarkState = useResetRecoilState(newHarkState);

  const resetAllRecordingStates = () => {
    resetRecordingCompleteState();
    resetNewHarkState();
  };
  return {
    resetAllRecordingStates,
  };
};

export default useResetRecordingAtoms;
