import {
  Box,
  Button,
  HStack,
  IconButton,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { IoMdPlay } from "react-icons/io";
import { FiTrash2 } from "react-icons/fi";

import videoThumbnail from "../../../assets/images/video_thumbnail.png";
import { DD_MON_YYYY as getTodayDate } from "../../../utils/dateFormatter";
import { newHarkState } from "../atoms/NewHarkStateAtom";
import { useRecoilState, useRecoilValue } from "recoil";
import { useEffect, useState } from "react";

const VideoSnapShot = () => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const { productName, thumbNail } = newHark;
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    console.log("thumbNail", thumbNail);
    thumbNail && setImageUrl(window.URL.createObjectURL(thumbNail));
  }, [thumbNail]);

  return (
    <HStack bg="white" p="5">
      <Box position="relative">
        <IconButton
          cursor="pointer"
          as={IoMdPlay}
          aria-label=""
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          marginY="auto"
          marginX="0"
          size="md"
          borderRadius="full"
          color="brand.500"
          p="2"
          backdropFilter="saturate(180%) blur(10px)"
          background="rgba(255,255,255,0.1);"
          _hover={{
            backdropFilter: "saturate(180%) blur(10px)",
            background: "rgba(255,255,255,0.3);",
          }}
        />
        <Image
          objectFit="cover"
          w="20"
          h="20"
          src={imageUrl || videoThumbnail}
        />
      </Box>
      <Stack width="full" textAlign="right">
        <Text fontSize="small" fontWeight="bold">
          {productName} - {getTodayDate()}
        </Text>
        {/* TODO: define inverted clolro mode */}
        <Box>
          <Button
            onClick={() =>
              setNewHark({ ...newHark, recording: null, thumbNail: undefined })
            }
            width="fit-content"
            fontWeight="normal"
            variant="ghost"
            leftIcon={<FiTrash2 />}
          >
            Delete
          </Button>
        </Box>
      </Stack>
    </HStack>
  );
};

export default VideoSnapShot;
