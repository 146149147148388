import { ICustomSelectOption } from "../../components/CustomSelect";
import { Heading, Stack, Text, Box, Image } from "@chakra-ui/react";
import { ICompany } from "../../@types/ICompany";
import { useEffect, useState } from "react";
import { getCompanies } from "../../api/companies";
import { useRecoilState } from "recoil";
import { newHarkState } from "./atoms/NewHarkStateAtom";
import companyLogo from "../../assets/svg/HarkLogoWide.svg";

const SelectMerchant = () => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchCompanies = async () => {
    try {
      setLoading(true);
      const data = await getCompanies();
      setLoading(false);
      setCompanies(data.companies);
      const defaultCompany = data.companies.find(
        (comp) =>
          comp.name.toLocaleLowerCase().includes("hark") ||
          comp.name.toLocaleLowerCase().includes("shoprite")
      );
      setNewHark({ ...newHark, company: defaultCompany!._id });
    } catch (err) {
      // TODO: better error handling
      console.log(err);
    }
  };

  const handleChange = (option: ICustomSelectOption | null) => {
    setNewHark({ ...newHark, company: option?.value ? option.value : "" });
  };

  useEffect(() => {
    fetchCompanies();
  }, []);
  const options = companies.map((company: ICompany) => ({
    element: (
      <Box
        width="full"
        bg="brand.25"
        display="flex"
        alignItems="center"
        gap="2"
        p="3"
        borderRadius="sm"
      >
        <Image height="20px" alt="logo" src={company.logo_url} />
        <Text fontWeight="medium">{company.name}</Text>
      </Box>
    ),
    value: company._id,
  }));

  return (
    // TODO: use smaller margins in mobile
    <Stack px="10" py="8" bg="white" borderRadius="md" maxWidth="700px">
      <Heading size="sm">Merchant</Heading>
      <Text>Hark will be sent to Propercloth:</Text>
      <Box
        width="full"
        bg="brand.25"
        display="flex"
        alignItems="center"
        gap="2"
        p="3"
        borderRadius="sm"
      >
        <Image maxHeight="30px" alt="logo" src={companyLogo} />
      </Box>
    </Stack>
  );
};

export default SelectMerchant;
