import { Container, Heading, Stack } from "@chakra-ui/react";
import SelectMerchant from "./SelectMerchant";
import ContactDetails from "./ContactDetails";
import RecordHark from "./RecordHark";
import { recordingCompleteState } from "./atoms/RecordingStateAtom";
import { useRecoilValue } from "recoil";
import SendHark from "./SubmitHark";

export default function NewHarkPage() {
  const recordingComplete = useRecoilValue(recordingCompleteState);
  return (
    <Container>
      <Stack gap="5">
        <Heading fontWeight="semibold" size="lg">
          Create a new hark
        </Heading>

        <SelectMerchant />
        <RecordHark />
        {recordingComplete && <ContactDetails />}

        <SendHark />
      </Stack>
    </Container>
  );
}
