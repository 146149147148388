import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { RecoilRoot } from "recoil";
import SidebarWithHeader from "./components/layout/NavBar";
import HarksPage from "./pages/HarksPage";
import NewHarkPage from "./pages/NewHarkPage/Index";
import NotificationsPage from "./pages/Notifications";
import SettingsPage from "./pages/Settings";
import SupportPage from "./pages/Support";

const colors = {
  brand: {
    25: "#F6FAFE",
    50: "#F7FAFC",
    100: "#EDF2F7",
    200: "#E2E8F0",
    300: "#CBD5E0",
    400: "#A0AEC0",
    500: "#4A5568",
    600: "#718096",
    700: "#2D3748",
    800: "#1A202C",
    900: "#171923",
  },
  secondary: {
    200: "#C9F2EE",
    700: "##DAE7EB",
    300: "#ABF0E9",
  },
};

const fonts = {
  heading: `Rubik, sans-serif`,
  body: `Inter, sans-serif`,
};

const theme = extendTheme({ colors, fonts });

export const App = () => (
  <RecoilRoot>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <SidebarWithHeader>
          <Routes>
            <Route index element={<HarksPage />} />
            <Route path="notifications" element={<NotificationsPage />} />
            <Route path="new" element={<NewHarkPage />} />
            <Route path="settings" element={<SettingsPage />} />
            <Route path="support" element={<SupportPage />} />
          </Routes>
        </SidebarWithHeader>
      </BrowserRouter>
    </ChakraProvider>
  </RecoilRoot>
);
