import Input from "../../components/CustomInput";
import {
  Box,
  Button,
  Grid,
  Heading,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import { FiEdit3 } from "react-icons/fi";
import { IContactDetails } from "../../@types/IContactDetails";
import { useRecoilState } from "recoil";
import { newHarkState } from "./atoms/NewHarkStateAtom";

const emptyContact: IContactDetails = {
  communicationMethod: "phone",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
};
const ContactDetails = () => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const [editMode, setEditMode] = useState<boolean>(true);
  const [contactDetails, setContactDetails] =
    useState<IContactDetails>(emptyContact);
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const onSave = (event: any) => {
    event.preventDefault();
    toggleEditMode();
    setNewHark({ ...newHark, contactDetails });
  };

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;
    setContactDetails({ ...contactDetails, [name]: value });
  };

  return (
    <Stack px="10" py="8" bg="white" borderRadius="md" maxWidth="700px">
      <Heading color="brand.800" size="sm">
        Contact Details
      </Heading>
      <Text color="GrayText">Hark will be sent to this merchant</Text>
      {editMode ? (
        <form onSubmit={onSave}>
          <Stack>
            <Grid templateColumns="repeat(2, 1fr)" gap="4">
              <Input
                value={contactDetails.firstName}
                name="firstName"
                onChange={handleChange}
                isRequired
                label="First Name"
                type="text"
              />
              <Input
                value={contactDetails.lastName}
                name="lastName"
                onChange={handleChange}
                isRequired
                label="Last Name"
                type="text"
              />
            </Grid>
            <Input
              value={contactDetails.email}
              name="email"
              onChange={handleChange}
              isRequired
              label="Contact Email"
              type="email"
            />
            <Input
              value={contactDetails.phone}
              name="phone"
              onChange={handleChange}
              isRequired
              label="Phone Number"
              type="tel"
              pattern="[0-9]{4,}$"
              title="Phone number should be at least 4 numbers"
            />

            <Stack>
              <Text fontSize="sm" color="GrayText">
                {" "}
                Preferred Communication Method:
              </Text>{" "}
              <RadioGroup
                size="sm"
                name="communication_method"
                onChange={(value: "email" | "phone") =>
                  setContactDetails({
                    ...contactDetails,
                    communicationMethod: value,
                  })
                }
                value={contactDetails.communicationMethod}
              >
                <Stack direction="row">
                  <Radio value="email">Email</Radio>
                  <Radio value="phone">Phone</Radio>
                </Stack>
              </RadioGroup>
            </Stack>
            <Box pt="3" display="flex" width="full" justifyContent="end">
              <Button
                type="submit"
                bg="brand.50"
                color="brand.700"
                fontWeight="medium"
              >
                Save Details
              </Button>
            </Box>
          </Stack>
        </form>
      ) : (
        <Stack pt="5">
          <HStack justifyContent="space-between">
            <Text color="GrayText">First Name</Text>{" "}
            <Text>{contactDetails.firstName}</Text>
          </HStack>

          <HStack justifyContent="space-between">
            <Text color="GrayText">Last Name</Text>{" "}
            <Text>{contactDetails.lastName}</Text>
          </HStack>

          <HStack justifyContent="space-between">
            <Text color="GrayText">Email</Text>{" "}
            <Text>{contactDetails.email}</Text>
          </HStack>

          <HStack justifyContent="space-between">
            <Text color="GrayText">Phone</Text>{" "}
            <Text>{contactDetails.phone}</Text>
          </HStack>

          <Box pt="5" display="flex" width="full" justifyContent="end">
            <Button
              onClick={() => {
                toggleEditMode();
                setNewHark({ ...newHark, contactDetails: null });
              }}
              type="submit"
              bg="brand.50"
              color="brand.700"
              fontWeight="medium"
              leftIcon={<FiEdit3 />}
            >
              Edit details
            </Button>
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

export default ContactDetails;
