import { useNavigate } from "react-router-dom";
import {
  Button,
  Heading,
  Image,
  Progress,
  Stack,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Icon,
  HStack,
} from "@chakra-ui/react";
import { recordingCompleteState } from "./atoms/RecordingStateAtom";
import { ImWarning } from "react-icons/im";
import { useRecoilValue } from "recoil";
import { newHarkState } from "./atoms/NewHarkStateAtom";
import { useState } from "react";
import { createHark } from "../../api/hark";
import useResetRecordingAtoms from "./hooks/useResetAtoms";
import harkSentSVG from "../../assets/svg/hark-sent.svg";

export const SendHark = () => {
  const navigate = useNavigate();
  const recordingComplete = useRecoilValue(recordingCompleteState);
  const newHark = useRecoilValue(newHarkState);
  const { company, contactDetails, recording } = newHark;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const { resetAllRecordingStates } = useResetRecordingAtoms();
  const [errorMessage, setErrorMessage] = useState("");

  const submitHark = async () => {
    onOpen();
    try {
      setLoading(true);
      setErrorMessage("");
      await createHark(newHark);
      resetAllRecordingStates();
      setLoading(false);
    } catch (err) {
      // TODO: better error handling
      console.log("Errror", err);
      setErrorMessage("Error Sending Hark, Try again");
      setLoading(false);
    }
  };

  const goHome = () => {
    onClose();
    navigate("/");
  };

  return (
    <>
      {/* TODO: is recordingcomplete atom really needed? */}
      <Button
        onClick={submitHark}
        size="lg"
        colorScheme="brand"
        variant="solid"
        isDisabled={
          !recordingComplete || !company || !contactDetails || !recording
        }
      >
        Submit Hark
      </Button>
      <Modal
        closeOnOverlayClick={loading ? false : true}
        size="xs"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          {loading && <ModalHeader>Sending Hark</ModalHeader>}
          <ModalCloseButton />
          <ModalBody>
            {loading && <Progress size="lg" isIndeterminate />}
            {!loading && !errorMessage && (
              <Stack mt="5">
                <Image w="16" src={harkSentSVG} />
                <Heading size="lg">Hark Sent!</Heading>
                <Text color="GrayText">
                  Your submission is being reviewed! Please expect a response
                  within 24 hours.
                </Text>
              </Stack>
            )}
            {!loading && errorMessage && (
              <Stack>
                <HStack color="red.400">
                  <Icon as={ImWarning} w="5" h="5" />{" "}
                  <Text fontSize="lg" fontWeight="bold">
                    Hark not sent
                  </Text>
                </HStack>
                <Text color="GrayText">{errorMessage}</Text>
              </Stack>
            )}
          </ModalBody>
          <ModalFooter>
            {/* TODO: use custom button  */}
            {!loading && !errorMessage && (
              <Button
                width="full"
                bg="brand.50"
                color="brand.700"
                onClick={goHome}
                _hover={{ bg: "brand.100" }}
              >
                Go to my Harks
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default SendHark;
