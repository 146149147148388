import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import useAudioRecording from "./hooks/useAudioRecorder";
import { useRecoilValue } from "recoil";
import AudioPlayBack from "./RecordAudio/AudioPlayBack";
import AudioRecorder from "./RecordAudio/AudioRecorder";
import RecordingOptions from "./RecordingOptions";
import { newHarkState } from "./atoms/NewHarkStateAtom";
import VideoSnapShot from "./RecordVideo/VideoSnapshot";

const RecordHark = () => {
  const { recording } = useRecoilValue(newHarkState);

  const {
    isRecordingAudio,
    playAudio,
    audioDuration,
    isPlayingAudio,
    audioVolumePercentage,
    startAudioRecording,
    stopAudioRecording,
    deleteRecording,
  } = useAudioRecording();

  return (
    <Stack px="10" py="8" gap="5" bg="white" borderRadius="md" maxWidth="700px">
      {/* Heading */}
      <Box>
        <Heading size="sm">Record Hark</Heading>
        <Text>Click Icons to Record Hark</Text>
      </Box>

      {/* IconButton */}
      {isRecordingAudio ? (
        <AudioRecorder
          audioDuration={audioDuration}
          audioVolumePercentage={audioVolumePercentage}
          stopAudioRecording={stopAudioRecording}
        />
      ) : (
        <RecordingOptions startAudioRecording={startAudioRecording} />
      )}

      {/* Audio Player */}
      {recording && (
        <Box bg="brand.25" p="5">
          {recording.type === "audio" && (
            <AudioPlayBack
              playAudio={playAudio}
              audioDuration={audioDuration}
              isPlayingAudio={isPlayingAudio}
              deleteRecording={deleteRecording}
            />
          )}

          {recording.type === "video" && <VideoSnapShot />}
        </Box>
      )}
    </Stack>
  );
};

export default RecordHark;
