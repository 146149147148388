import axios from "axios";
import { INewHark } from "../@types/IHark";
import audioThumbnail from "../assets/images/audio_thunail.png";

const BASE_URL =
  process.env.REACT_APP_API_BASE_URL ||
  "https://second-caster-340917.uc.r.appspot.com/api/v1";

export async function createHark(newHark: INewHark) {
  if (!newHark.contactDetails)
    throw new Error("please provide contact details");
  const { firstName, lastName, email, phone, communicationMethod } =
    newHark.contactDetails;

  const formData = new FormData();
  console.log("Hark RECORDING ", newHark?.recording?.source);
  const file = new File(
    [newHark?.recording?.source as Blob],
    `${newHark.recording?.type}.${newHark.recording?.mediaType}`
  );
  formData.append("recording", file);
  formData.append("name", `${firstName} ${lastName}`);
  formData.append("email", email);
  formData.append("phone", phone);
  formData.append("communication_method", communicationMethod);
  formData.append("company", newHark.company);
  formData.append("product_name", newHark.productName);

  const res = await fetch(audioThumbnail);
  let thumbnail = await res.blob();
  formData.append("thumbnail", thumbnail);

  const response = await axios.post(`${BASE_URL}/tasks`, formData, {
    headers: { "content-type": "multipart/form-data" },
  });
  return response.data;
}
