import { Box, HStack, Icon, Input, Stack, Text } from "@chakra-ui/react";
import { IoMdPlay, IoMdPause, IoMdCheckmark, IoMdClose } from "react-icons/io";
import { FiEdit3 } from "react-icons/fi";
import { GiSoundWaves } from "react-icons/gi";
import { IconButton } from "../TooltipButton";

import {
  DD_MON_YYYY as getTodayDate,
  secondsToHH_MM_SS,
} from "../../../utils/dateFormatter";
import { useRecoilState } from "recoil";
import { newHarkState } from "../atoms/NewHarkStateAtom";
import { useState } from "react";

interface AudioPlayBackProps {
  playAudio: () => void;
  audioDuration: number;
  isPlayingAudio: boolean;
  deleteRecording: () => void;
}

const AudioPlayBack = ({
  playAudio,
  audioDuration,
  isPlayingAudio,
  deleteRecording,
}: AudioPlayBackProps) => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const [editMode, setEditMode] = useState(true);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleEnterKey = (e: any) => {
    if (e.key === "Enter" && newHark.productName) toggleEditMode();
  };

  return (
    <Stack bg="white" px="5" pb="0" pt="5" position="relative">
      <IconButton
        onClick={deleteRecording}
        size="xs"
        bg="white"
        boxShadow="lg"
        p="1"
        as={IoMdClose}
        position="absolute"
        top="-2"
        right="-2"
      />
      {editMode ? (
        <HStack paddingBottom={2}>
          <Input
            value={newHark.productName}
            placeholder="Enter Product name"
            onKeyPress={handleEnterKey}
            onChange={(e) =>
              setNewHark({ ...newHark, productName: e.target.value })
            }
            size="sm"
          />
          <IconButton
            size="sm"
            onClick={toggleEditMode}
            isDisabled={!newHark.productName}
          >
            <IoMdCheckmark />
          </IconButton>
        </HStack>
      ) : (
        <HStack>
          <Text onClick={toggleEditMode} fontSize="small" fontWeight="bold">
            {newHark.productName} - {getTodayDate()}
          </Text>
          <IconButton size="sm" onClick={toggleEditMode}>
            <FiEdit3 />
          </IconButton>
        </HStack>
      )}
      <HStack>
        <IconButton
          onClick={playAudio}
          color="brand.700"
          bg="white"
          border="1px"
          borderColor="brand.700"
        >
          {isPlayingAudio ? <IoMdPause /> : <IoMdPlay />}
        </IconButton>
        <HStack height={16} gap={0} overflow="hidden">
          {new Array(20).fill(0).map((_, index) => (
            <Icon
              key={index}
              w={isPlayingAudio ? Math.ceil(Math.random() * 20) : 16}
              h={isPlayingAudio ? Math.ceil(Math.random() * 20) : 16}
              as={GiSoundWaves}
              color={isPlayingAudio ? "brand.200" : "gray.200"}
            />
          ))}
        </HStack>
        <Text w="48" textAlign="right" fontSize="xs">
          {secondsToHH_MM_SS(audioDuration)}
        </Text>
      </HStack>
    </Stack>
  );
};

export default AudioPlayBack;
