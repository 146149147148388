import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Text,
  HStack,
  Stack,
  Input,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { FiVideo } from "react-icons/fi";
import { IoMdRefresh } from "react-icons/io";
import { useRecoilState } from "recoil";
import { secondsToHH_MM_SS } from "../../../utils/dateFormatter";
import { generateThumbnail } from "../../../utils/generateThumbnail";
import { newHarkState } from "../atoms/NewHarkStateAtom";
import { recordingCompleteState } from "../atoms/RecordingStateAtom";
import useVideoRecorder from "../hooks/useVideoRecorder";
import ToolTipButton from "../TooltipButton";
import VideoPlayback from "./VideoPlayBack";
let hasUsedVideoStream = false;

const RecordVideo = () => {
  const recordingComplete = useRecoilState(recordingCompleteState);
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loadingCams, setLoadingCams] = useState(false);
  const {
    isOpen: isVideoPlayBackOpen,
    onOpen: openVideoPlayback,
    onClose: closeVideoPlayback,
  } = useDisclosure();

  const {
    videoStream,
    requestAccessToMediaDevices,
    closeCamera,
    startRecording,
    stopRecording,
    duration,
    isRecording,
  } = useVideoRecorder();

  const vidRef = useRef(null);
  // const handlePlayVideo = () => {

  //   vidRef?.current?.play();
  // };

  const openModal = () => {
    setLoadingCams(true);
    onOpen();
    requestAccessToMediaDevices().then(() => {
      setLoadingCams(false);
    });
  };

  const closeModal = () => {
    hasUsedVideoStream = false;
    closeCamera();
    onClose();
  };

  const handleStopRecording = () => {
    stopRecording();
    openVideoPlayback();
    onClose();
  };

  const retryRecroding = () => {
    setNewHark({ ...newHark, recording: null });
    hasUsedVideoStream = false;
    closeVideoPlayback();
    openModal();
  };

  return (
    <>
      <ToolTipButton
        isDisabled={Boolean(newHark.recording)}
        onClick={openModal}
        label="Record Video"
      >
        <FiVideo />
      </ToolTipButton>

      {newHark.recording ? (
        <VideoPlayback
          duration={duration}
          closeCamera={closeCamera}
          isOpen={isVideoPlayBackOpen}
          onOpen={openVideoPlayback}
          onClose={closeVideoPlayback}
          onRetry={retryRecroding}
        />
      ) : (
        <Modal isOpen={isOpen} onClose={closeModal} closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize="md">
              <HStack justify="space-between">
                <Text> Record Video Clip</Text>
              </HStack>
            </ModalHeader>
            <ModalCloseButton size="sm" isDisabled={!videoStream} />
            <ModalBody>
              {loadingCams ? (
                <Center paddingY={20}>
                  <Spinner
                    thickness="5px"
                    speed="1s"
                    emptyColor="gray.200"
                    color="brand.100"
                    size="xl"
                  />
                </Center>
              ) : (
                <Box>
                  <video
                    playsInline
                    ref={(video) => {
                      if (video && videoStream && !hasUsedVideoStream) {
                        hasUsedVideoStream = true;
                        video.srcObject = videoStream;
                      }
                    }}
                    muted
                    autoPlay
                  ></video>
                </Box>
              )}
            </ModalBody>

            <ModalFooter>
              {isRecording ? (
                <HStack width="full" justify="space-between">
                  <Text>{secondsToHH_MM_SS(duration)}</Text>
                  <Box display="flex">
                    <Button
                      variant="outline"
                      colorScheme="gray"
                      mr={3}
                      onClick={closeModal}
                      isDisabled={!videoStream}
                    >
                      Pause
                    </Button>
                    {/* TODO:  make colorscheme or custom button for this button with stye*/}
                    <Button
                      isDisabled={!videoStream}
                      _hover={{ bg: "brand.100" }}
                      bg="brand.50"
                      variant="solid"
                      onClick={handleStopRecording}
                    >
                      Stop
                    </Button>
                  </Box>
                </HStack>
              ) : (
                <>
                  <Button
                    variant="outline"
                    colorScheme="gray"
                    mr={3}
                    onClick={closeModal}
                    isDisabled={!videoStream}
                  >
                    Cancel
                  </Button>
                  {/* TODO:  make colorscheme or custom button for this button with stye*/}
                  <Button
                    isDisabled={!videoStream}
                    _hover={{ bg: "brand.100" }}
                    bg="brand.50"
                    variant="solid"
                    onClick={startRecording}
                  >
                    Record
                  </Button>
                </>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default RecordVideo;
