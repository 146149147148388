import { HStack } from "@chakra-ui/react";
import { FiMonitor, FiMic } from "react-icons/fi";
import { IoMdAttach } from "react-icons/io";
import ToolTipButton from "./TooltipButton";
import RecordVideo from "./RecordVideo/RecordVideoModal";
import { useRecoilValue } from "recoil";
import { newHarkState } from "./atoms/NewHarkStateAtom";
interface RecordingOptionsProp {
  startAudioRecording: () => void;
}
const RecordingOptions = ({ startAudioRecording }: RecordingOptionsProp) => {
  const newHark = useRecoilValue(newHarkState);
  const isDisabled = Boolean(newHark.recording);
  return (
    <HStack justifyContent="space-between">
      <HStack>
        {/* Record Screen */}
        <ToolTipButton
          isDisabled={isDisabled}
          onClick={() => {}}
          label="Record Screen"
        >
          <FiMonitor />
        </ToolTipButton>

        {/* Record Video */}
        <RecordVideo />

        {/* Record Audio */}
        <ToolTipButton
          isDisabled={isDisabled}
          onClick={startAudioRecording}
          label="Record Audio"
        >
          <FiMic />
        </ToolTipButton>
      </HStack>

      {/* Attach file */}
      <ToolTipButton onClick={() => {}} label="Attach File">
        <IoMdAttach />
      </ToolTipButton>
    </HStack>
  );
};

export default RecordingOptions;
