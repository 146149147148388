import {
  Box,
  Button,
  Heading,
  HStack,
  Stack,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
  VStack,
} from "@chakra-ui/react";
import { HiOutlinePlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import NoHarksSvg from "../assets/svg/NoHarks.svg";

export default function HarksPage() {
  return (
    <Box
      width="full"
      height="full"
      px={{ base: "0", md: "16" }}
      display="flex"
      justifyContent="center"
    >
      <Stack spacing={10} maxWidth="800px">
        <Box
          px="10"
          py="5"
          borderRadius="xl"
          w="100%"
          bgGradient="linear(to-bl, #E2E8F0, #DAE7EB)"
          boxShadow="sm"
        >
          <Stack>
            <Heading size="md" mb="5">
              Get Started
            </Heading>
          </Stack>
          <Text fontSize="sm">
            Hark is the fastest means of communicating with us and getting
            resolutions to issues
          </Text>
          <HStack width="full" mt="5" justifyContent="end">
            <Button fontWeight="normal" variant="ghost">
              Dismiss
            </Button>{" "}
            <Button fontWeight="normal">Watch Video</Button>
          </HStack>
        </Box>

        <HStack justifyContent="space-between">
          <Heading color="brand.800">Library</Heading>
          <Link to="/new">
            <Button
              variant="solid"
              colorScheme="brand"
              leftIcon={<HiOutlinePlus />}
            >
              Create New Hark
            </Button>
          </Link>
        </HStack>

        <Tabs variant="unstyled">
          <TabList>
            <Tab
              color="gray.500"
              _selected={{
                bg: "brand.50",
                color: "brand.700",
                borderBottom: "2px",
                borderBottomColor: "brand.700",
              }}
            >
              Active Harks
            </Tab>
            <Tab
              color="gray.500"
              _selected={{
                bg: "brand.50",
                color: "brand.700",
                borderBottom: "2px",
                borderBottomColor: "brand.700",
              }}
            >
              Resolved Harks
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Center>
                <VStack mt="5" maxWidth="400px" spacing={5} alignItems="center">
                  <img src={NoHarksSvg} alt="Hark logo" />
                  <Text>No Hark Found</Text>
                  <Text color="gray.500" fontSize="sm">
                    Your search “Landing page design” did not match any
                    projects. Please try again.
                  </Text>

                  <Link to="/new">
                    <Button
                      variant="solid"
                      colorScheme="brand"
                      leftIcon={<HiOutlinePlus />}
                    >
                      New Hark
                    </Button>
                  </Link>
                </VStack>
              </Center>
            </TabPanel>
            <TabPanel>
              <p></p>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </Box>
  );
}
